<script setup>
import dayjs from 'dayjs';
import { isEqual } from 'lodash-es';
import { useHawkPivotTableHelperComposable } from '~/common/components/organisms/hawk-pivot-table/helper-composable.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useFamConstants } from '~/forms-as-module/composables/fam-constants.composable.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  content_height: {
    type: Number,
  },
});

const $services = inject('$services');
const { getFormattedDate } = useFamConstants();
const dashboard_store = useDashboardStore();
const { preProcessData } = useHawkPivotTableHelperComposable();

const state = reactive({
  data: [],
  active_chart: null,
  is_loading: true,
  pivot_config: {},
  last_updated_at: null,
});
const pivot_instance = ref({});

const get_pivot_table_height = computed(() => {
  if (props.id === 'preview')
      return Number.isNaN(props.content_height) ? false : (props.content_height - 96);

  const widget_height = (((props.data.h || 22) * 20) - 44);
  return (props.content_height || widget_height) - 46;
});

function updatePivotConfig() {
  dashboard_store.pivot_table_instance = { ...pivot_instance.value?.flexmonster, active_chart: state.active_chart };
}

function getTimelineQuery() {
  let date_range = props.data?.data?.timeline_type === 'custom' ? props.data?.data?.timeline_range : getFormattedDate(props.data.data.timeline_type);

  return {
    start_date: dayjs(date_range[0]).format('YYYY-MM-DD'),
    end_date: dayjs(date_range[1]).format('YYYY-MM-DD'),
  };
}

async function getData() {
  try {
    state.is_loading = true;
    const date_query = getTimelineQuery();
    const query = {
      status: 'published',
      workflow: props.data.data.workflows,
      dashboard: true,
      ...date_query,
    };

    const { data } = await $services.inventory_reports.export_transaction_report({ query });
    state.data = preProcessData(data);

    if (props.data?.data?.properties?.pivot_config) {
      state.pivot_config = props.data?.data?.properties?.pivot_config;
      pivot_instance.value?.flexmonster.setReport({
        options: {
          grid: { showHeaders: false },
          configuratorButton: false,
        },
        formats: [{
          name: '', // an empty name allows specifying the default format for all the measures
          decimalPlaces: '2',
          thousandsSeparator: ',',
        }],
        ...state.pivot_config,
        dataSource: state.data,
      });
    }
    else {
      pivot_instance.value?.flexmonster.updateData(state.data);
    }
    state.is_loading = false;
  }
  catch (err) {
    logger.error(err);
    state.is_loading = false;
  }
}

watch(() => props.data, async (new_val, old_val) => {
  if (new_val && !isEqual(new_val, old_val))
    await getData();
}, { immediate: true, deep: true });
</script>

<template>
  <div>
    <HawkPivotTable
      v-show="!state.is_loading"
      :show_hide_chart="id === 'preview'"
      :show_header="id === 'preview'"
      :pivot_table_height="get_pivot_table_height"
      :pivot_table_data="state.data"
      :pivot_table_mapping="state.data?.mapping || {}"
      :active_chart="state.active_chart"
      @pivotInstance="pivot_instance = $event"
      @pivotConfig="updatePivotConfig"
      @updateActiveChart="state.active_chart = $event; updatePivotConfig()"
    />
    <HawkLoader v-if="state.is_loading" container_class="m-1" />
  </div>
</template>
