<!-- eslint-disable unused-imports/no-unused-vars -->
<script setup>
import dayjs from 'dayjs';
import { cloneDeep, groupBy, keyBy } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import InventoryCustomFieldInput from '~/inventory/components/inventory-custom-fields/inventory-custom-field-input.vue';
import InventoryMailPopup from '~/inventory/components/inventory-mail-popup.vue';
import InventoryFormWorkflows from '~/inventory/components/inventory-transaction-form/inventory-form-workflows.vue';
import InventoryTransactionFormItems from '~/inventory/components/inventory-transaction-form/inventory-transaction-form-items.vue';
import InventoryVendorForm from '~/inventory/components/inventory-vendor/inventory-vendor-form-popup.vue';
import InventoryWarehouseForm from '~/inventory/components/inventory-warehouse/inventory-warehouse-form.vue';
import { useCustomFields } from '~/inventory/composables/inventory-custom-fields.composable.js';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import { useStockItems } from '../composables/inventory-stock-items.composable';

const { $t, $services, $toast, auth_store, route, router } = useCommonImports();
const inventory_store = useInventoryStore();
const form$ = ref({});
const form = ref({});
const from_stock = ref(null);
const to_stock = ref(null);
const { validateStocks } = useStockItems();
const state = reactive({
  adjustment_lines: [],
  current_date: dayjs().format('YYYY-MM-DD'),
  loading: false,
  is_draft_loading: false,
  initial_loading: false,
  disable_cta: false,
  disabled_save: false,
  form_submissions: [],
  transaction_id: null,
});

const adjustment_details = computed(() => inventory_store.history_details);

const custom_fields_config = ref([]);
const get_adjustment_custom_fields = computed(() =>
  inventory_store.get_custom_fields({ uid: route.params.workflow_id }, true),
);

const { map_custom_fields } = useCustomFields();

const min_date = ref({});

const workflow = computed(
  () => inventory_store.workflows_map[route.params.workflow_id],
);
const is_not_on_system = computed(() => {
  return !workflow.value.from_status?.length;
});
const is_quantity_filled = computed(() => {
  return !state.adjustment_lines.some(line => !line.quantity);
});
const adjustment_form_submissions = computed(() => {
  const form_submissions = adjustment_details.value?.uid === route.params.transaction_id ? (adjustment_details.value?.form_submissions || []) : [];
  const has_items = workflow.value.form_workflows.filter(workflow => workflow?.items?.length).length;
  return { form_submissions, has_items };
});

async function generate_transaction_number() {
  try {
    const { data } = await inventory_store.$services.inventory_workflows.generate_transaction_number({
      query: { workflow: workflow.value.uid },
    });

    if (!data.latest_date) {
      min_date.value = null;
    }
    else {
      const latest_date = dayjs(data.latest_date).format('YYYY-MM-DD');
      min_date.value = latest_date <= state.current_date ? latest_date : state.current_date;
    }

    state.initial_loading = false;
    nextTick(() => {
      form.value.number = data.sequence_no;
      form.value.date = state.current_date;
      form$.value.update({ ...form.value });
    });
  }
  catch ({ data: error }) {
    state.initial_loading = false;
    const { title, message } = inventory_store.get_error_status(error?.error_code) || {};
    $toast({
      title: title || 'Something went wrong',
      text: message || 'Please try again',
      type: 'error',
    });
  }
  finally {
    state.initial_loading = false;
  }
}

const source = computed(() => {
  const items = inventory_store[`${workflow.value.source}s`].filter(item => item.uid !== form.value.to_stock);
  const name = workflow.value.source;
  const label = name[0].toUpperCase() + name.slice(1);
  return {
    items,
    name,
    label,
    default: (workflow.value.source === 'asset' && route.params.asset_id) ? route.params.asset_id : undefined,
  };
});

const destination = computed(() => {
  const items = inventory_store[`${workflow.value.destination}s`].filter(item => item.uid !== form.value?.from_stock);
  const name = workflow.value.destination;
  const label = name[0].toUpperCase() + name.slice(1);
  return {
    items,
    name,
    label,
    default: (workflow.value.destination === 'asset' && route.params.asset_id) ? route.params.asset_id : undefined,
  };
});

async function validateForm(as_draft = false) {
  if (!serial_number_validation())
    return;

  if (!is_quantity_filled.value) {
    $toast({ text: 'Items quantity can not be zero', type: 'error', title: 'Fill Items' });
    return;
  }

  if (as_draft) {
    state.is_draft_loading = true;
    await form$.value.validate();
  }
  if (form$.value.invalid)
    state.is_draft_loading = false;
}

async function processFormData(as_draft = false) {
  const form_data = cloneDeep(form$.value.requestData);

  if (form_data?.attachments?.length) {
    form_data.attachments = form_data.attachments?.map((attachment) => {
      const matching_attachment = form$.value.data?.attachments?.find(a => a.uppy_id === attachment.uid);
      if (attachment?.uid)
        return attachment;
      return {
        ...attachment,
        file_name: matching_attachment?.fname || '',
        name: matching_attachment?.fname || '',
      };
    });
  }

  form_data.date = formatDate(form_data.date);

  form_data.status = as_draft ? 'draft' : 'published';
  form_data.workflow = workflow.value.uid;

  if (form_data.from_stock && !form_data.from_stock.uid) {
    form_data.from_stock = {
      uid: form_data.from_stock,
      type: workflow.value.source,
    };
  }

  if (form_data.to_stock && !form_data.to_stock.uid) {
    form_data.to_stock = {
      uid: form_data.to_stock,
      type: workflow.value.destination,
    };
  }

  if (!form_data.to_stock && workflow.value.stock_operation === 'no_op')
    form_data.to_stock = form_data.from_stock;

  const filtered_adjustment_lines = state.adjustment_lines.filter(line => line?.item);
  let adjustment_lines = await processAdjustmentLines(filtered_adjustment_lines, form_data, as_draft); ;

  form_data.adjustment_lines = adjustment_lines;
  form_data.custom_fields = form_data?.custom_fields?.map((custom_field) => {
    return {
      created_at: new Date(),
      created_by: auth_store?.logged_in_user_details?.user_id,
      ...custom_field,
    };
  }) || [];
  form_data.form_submissions = state.form_submissions;

  if (!form_data.date)
    delete form_data.date;

  if (!as_draft) {
    form_data.trigger_emails = [];
    const custom_fields_post_promises = [];
    const custom_fields_patch_promises = [];
    adjustment_lines.forEach(async (line) => {
      const custom_fields = keyBy(inventory_store.get_custom_fields({ attached_to: 'stock_unit' }), 'name');

      if (Object.keys(custom_fields || {}).length) {
        const imported_custom_fields = line.stock_input?.data?.columns.filter(item => item.data !== 'batch_number' && item.data !== 'serial_no').map(item => item.text);
        imported_custom_fields?.forEach((field) => {
          if (custom_fields[field]) {
            const custom_field = custom_fields[field];
            const is_item_attached_to_any_custom_fields = custom_field.item_meta?.uid === line.item;

            if (!is_item_attached_to_any_custom_fields) {
              custom_fields_patch_promises.push(inventory_store.update_custom_field({
                id: custom_fields[field].uid,
                body: {
                  custom_field: {

                    attached_to: [
                      ...(custom_fields[field]?.attached_to || []),
                      {
                        type: 'stock_unit',
                        uid: line.item,
                        active: true,
                        properties: { mandatory: false },
                      },
                    ],
                  },
                },
              }));
            }
          }
          else {
            custom_fields_post_promises.push($services.inventory_custom_fields.post({
              body: {
                custom_field: {
                  name: field,
                  description: null,
                  type: 'text',
                  active: true,
                  attached_to: [
                    {
                      active: true,
                      type: 'stock_unit',
                      uid: line.item,
                      properties: { mandatory: false },
                    },
                  ],
                },
              },
            }));
          }
        });
      }
      else {
        const imported_custom_fields = line.stock_input.data?.columns.filter(item => item.data !== 'batch_number' && item.data !== 'serial_no').map(item => item.text);
        imported_custom_fields.forEach((field) => {
          custom_fields_post_promises.push($services.inventory_custom_fields.post({
            body: {
              custom_field: {
                name: field,
                description: null,
                type: 'text',
                active: true,
                attached_to: [
                  {
                    active: true,
                    type: 'stock_unit',
                    uid: line.item,
                    properties: {
                      mandatory: false,
                    },
                  },
                ],
              },
            },
          }));
        });
      }
    });
    if (custom_fields_post_promises.length) {
      const responses = await Promise.all(custom_fields_post_promises);
      responses.forEach((response) => {
        const custom_field = response.data.custom_field;
        inventory_store.add_custom_field({
          uid: custom_field.uid,
          value: custom_field,
        });
      });
    }
    if (custom_fields_patch_promises.length)
      await Promise.all(custom_fields_patch_promises);

    const custom_fields = keyBy(inventory_store.get_custom_fields({ attached_to: 'stock_unit' }), 'name');
    adjustment_lines = adjustment_lines.map((line) => {
      const stock_input_table_data = line.stock_input?.data?.data;
      const serial_number_item = {};
      stock_input_table_data?.forEach((item) => {
        const row = item;

        serial_number_item[item.serial_no] = { custom_fields: [] };
        Object.keys(row).forEach((key) => {
          if (key !== 'serial_no' && key !== 'batch_number' && key !== '' && key !== '_' && key !== 'Serial number' && key !== undefined && custom_fields[key]?.uid) {
            serial_number_item[item.serial_no].custom_fields.push({
              value: row[key],
              uid: custom_fields[key]?.uid,

            });
          }
        });
      });
      const serial_numbers = line.serial_numbers?.reduce((acc, curr) => {
        acc[curr.serial_no] = {
          ...curr,
          custom_fields: serial_number_item[curr.serial_no]?.custom_fields,
        };
        return acc;
      }, {});

      line.serial_numbers?.forEach((item, index) => {
        if (serial_numbers[item.serial_no])
          line.serial_numbers[index].custom_fields = serial_numbers[item.serial_no].custom_fields;
      });
      return line;
    });
  }

  return form_data;
}

async function processAdjustmentLines(lines, form_data, as_draft) {
  const adjustment_lines = [];

  for (const line of lines) {
    const item = line?.item;

    if (!item.is_serial_number)
      delete line.serial_numbers;

    let stock_snapshot;

    if (line.stock_input?.input?.length && !as_draft) {
      const { valid_stocks, response } = await validateStocks(line.stock_input?.input, {
        workflow: workflow.value,
        item,
        from_stock: form_data.from_stock?.uid,
        to_stock: form_data.to_stock?.uid,
        ...((line.stock_input?.data?.track_by && line.stock_input?.data?.track_by?.uid !== 'serial_no') && {
          field_uid: line.stock_input?.data?.track_by?.uid,
        }),
      });

      const uom_symbol = inventory_store.uom_map[item?.uom]?.symbol;

      if (is_not_on_system.value && item.is_batch_number) {
        const stock_snapshot_payload = [];
        const table_data = groupBy(line.stock_input?.data?.data, 'batch_number');
        const valid_serial_numbers = valid_stocks.map(item => item.number);

        Object.keys(table_data).forEach((key) => {
          stock_snapshot_payload.push({
            type: 'pallet',
            number: key,
            valid: true,
            name: 'Pallet',
            children_type: uom_symbol,
            children: table_data[key].map(item => ({
              type: 'serial',
              number: item.serial_no,
              valid: true,
              name: 'serial number',

            })).filter(item => valid_serial_numbers.includes(item.number)),
          });
        });
        stock_snapshot = stock_snapshot_payload.filter(item => item.children.length);
      }
      else {
        stock_snapshot = response.data.stock_units;
      }
    }

    adjustment_lines.push({
      ...line,
      item: item.uid,
      unit_price: item?.cost,
      description: line.description,
      unit_price_currency: item?.cost_currency,
      ...(as_draft !== true && {
        stock_snapshot,
      }),
    });
  }

  return adjustment_lines;
}

function formatDate(date) {
  if (dayjs(date).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD'))
    return null;
  return dayjs()
    .utc()
    .set('date', dayjs(date).get('date'))
    .set('month', dayjs(date).get('month'))
    .set('year', dayjs(date).get('year'))
    .format('YYYY-MM-DDTHH:mm:ss[Z]');
}

function getTransactionIdDetails(return_transaction_id) {
  const transaction_id = state.transaction_id || route.params.transaction_id;
  const has_return_transaction_id = (typeof return_transaction_id === 'boolean' && return_transaction_id);
  return { has_return_transaction_id, transaction_id };
}

async function save(as_draft, return_transaction_id = false) {
  const { has_return_transaction_id, transaction_id } = getTransactionIdDetails(return_transaction_id);

  if (has_return_transaction_id && transaction_id)
    return transaction_id;

  const is_draft = (typeof as_draft === 'boolean') ? as_draft : false;
  await validateForm(is_draft || false);
  const form_data = await processFormData(is_draft || false);
  try {
    if (transaction_id) {
      await inventory_store.update_adjustment({
        id: transaction_id,
        body: { adjustment: form_data },
        query: { asset: route.params.asset_id },
      });
    }
    else {
      const adjustment = await inventory_store.create_adjustment({
        body: { adjustment: form_data },
        query: { asset: route.params.asset_id },
      });
      if (has_return_transaction_id) {
        state.is_draft_loading = false;
        state.transaction_id = adjustment.uid;
        return adjustment.uid;
      }
    }

    state.is_draft_loading = false;
    if (form_data.status === 'draft') {
      $toast({
        title: `${workflow.value.name}${$t(' draft saved')}`,
        text: `${workflow.value.name}${$t(' has been saved as a draft successfully.')}`,
        type: 'success',
      });
    }
    else {
      $toast({
        title: `${workflow.value.name}${$t(' published')}`,
        text: `${workflow.value.name}${$t(' has been published successfully.')}`,
        type: 'success',
      });
    }
    router.push({ name: 'inventory-transactions', params: { ...route.params } });
  }
  catch ({ data: error }) {
    state.is_draft_loading = false;
    if (has_return_transaction_id)
      return '';
    return showErrorToast(error);
  }
}

function showErrorToast(error) {
  const { title, message } = inventory_store.get_error_status(error?.error_code) || {};

  $toast({
    title: title || 'Something went wrong',
    text: message || 'Please try again',
    type: 'error',
  });
  return error;
}

async function get_details({ uid }) {
  await inventory_store.set_history_details({
    id: uid,
    query: { asset: route.params.asset_id },
  });
}

function serial_number_validation() {
  const value = state.adjustment_lines?.find((line) => {
    if (line?.item?.is_serial_number) {
      const length = line.serial_numbers?.[0]?.serial_numbers ? line?.serial_numbers?.reduce((acc, curr) => acc + curr.serial_numbers?.length, 0) : line?.serial_numbers?.length;
      return length !== line?.quantity;
    }
    return false;
  });
  if (value) {
    $toast({ text: 'Fill serial numbers', type: 'error', title: 'Serial Numbers' });
    return false;
  }
  else {
    return true;
  }
}

function format_attachment_data(name, files) {
  return {
    [name]: files.map((file) => {
      return file.uid
        ? file
        : {
            uid: file.uppy_id,
            service: file.service_object,
            original_file: file.upload_url,
            file_type: file.type,
            name: file.name,
            size: file.size,
            stage: file?.service_object?.stage || 'vault',
            created_at: file.lastModifiedDate,
            created_by: auth_store?.logged_in_user_details?.user_id,
          };
    }),
  };
}

const { open: openAddWarehouseModal, close: closeAddWarehouseModal, patchOptions: patchWarehouseOptions } = useModal({
  component: InventoryWarehouseForm,
  attrs: {
    onClose() {
      closeAddWarehouseModal();
    },
  },
});

function create_warehouse(target) {
  patchWarehouseOptions({
    attrs: {
      onSave(uid) {
        target.load(uid);
        closeAddWarehouseModal();
      },
    },
  });
  openAddWarehouseModal();
}

const { open: openAddVendorModal, close: closeAddVendorModal, patchOptions: patchVendorOptions } = useModal({
  component: InventoryVendorForm,
  attrs: {
    onClose() {
      closeAddVendorModal();
    },
  },
});

function create_vendor(target) {
  patchVendorOptions({
    attrs: {
      onSave(uid) {
        target.load(uid);
        closeAddVendorModal();
      },
    },
  });
  openAddVendorModal();
}

function create_location(target, data) {
  // form.value?.[target]
  if (data.name === 'warehouse')
    create_warehouse(target);

  if (data.name === 'vendor')
    create_vendor(target);
}

function load_attachment_data(files) {
  return files.map((file) => {
    return file.url
      ? {
          ...file,
          file_name: file.name,
          service: {
            ...file.service,
            url: file.url,
          },
        }
      : file;
  });
}

const { open: openMailSettings, close: closeMailSettings, patchOptions: patchMailSettings } = useModal({
  component: InventoryMailPopup,
});

function addEmails() {
  return new Promise((resolve, reject) => {
    patchMailSettings({
      attrs: {
        has_cancel: false,
        onClose() {
          closeMailSettings();
          resolve([]);
          state.is_draft_loading = false;
        },
        onSave(data) {
          closeMailSettings();
          resolve(data.emails);
        },
      },
    });
    openMailSettings();
  });
}

function onFormUpdated(event) {
  if (event.type === 'upload_started')
    state.loading = true;
  else if (event.type === 'upload_completed')
    state.loading = false;
}

async function validate_serial_numbers({ serial_numbers, item }) {
  if (!serial_numbers.length)
    return [];
  const res = await inventory_store.$services.inventory_items.validate_serial_numbers({
    body: {
      stock_units: {
        serial_numbers,
        item,
        workflow: workflow.value?.uid,
      },
    },
    query: {
      asset: route.params.asset_id,
    },
  });

  return res.data?.stock_units;
}

async function validate_stock_units({ serial_numbers, item }) {
  if (!serial_numbers.length)
    return [];
  const res = await inventory_store.$services.inventory_items.validate_stock_units({
    body: {
      stock_units: {
        search: serial_numbers,
        workflow: workflow.value?.uid,
        item,
        location_id: adjustment_details.value.from_stock.uid,
      },
    },
    query: {
      asset: route.params.asset_id,
    },
  });

  return res.data?.stock_units;
}
watch(get_adjustment_custom_fields, async () => {
  await map_custom_fields(custom_fields_config, get_adjustment_custom_fields.value, form.value.custom_fields);
  form.value.custom_fields = custom_fields_config.value.map(f => ({
    uid: f.uid,
    value: f.value,
  }));
  await form$.value.load(form.value, true);
});
onMounted(async () => {
  state.initial_loading = true;
  if (route.params.transaction_id) {
    await get_details({ uid: route.params.transaction_id });
    await generate_transaction_number();
    await map_custom_fields(custom_fields_config, get_adjustment_custom_fields.value, adjustment_details.value.custom_fields);
    form.value.from_stock = adjustment_details.value.from_stock.uid;
    form.value.to_stock = adjustment_details.value.to_stock.uid;
    form.value.custom_fields = custom_fields_config.value.map(f => ({
      uid: f.uid,
      value: f.value,
    }));
    form.value.number = adjustment_details.value.number;
    form.value.remarks = adjustment_details.value.remarks;
    form.value.uid = adjustment_details.value.uid;
    form.value.attachments = adjustment_details.value.attachments;
    await form$.value.load(form.value, true);
    state.adjustment_lines = await Promise.all(adjustment_details.value.adjustment_lines.map(async (line) => {
      return {
        item: adjustment_details.value.items_map[line.item],
        description: line?.description,
        quantity: line?.quantity,
        invalid_stock_units: line?.invalid_stock_units,
        serial_numbers: line?.serial_numbers,
        stock_input: line?.stock_input,
      };
    }));
  }
  else {
    await generate_transaction_number();
    await form$.value.load(form.value, false);
    await map_custom_fields(custom_fields_config, get_adjustment_custom_fields.value);
  }
  state.initial_loading = false;
});

const has_only_draft = computed(() => {
  return route.params.transaction_id ? !adjustment_details.value?.permissions?.publish && adjustment_details.value?.permissions?.update : !workflow.value.permissions.create && workflow.value.permissions.draft;
});
</script>

<template>
  <div>
    <HawkPageHeader>
      <template #title>
        <div>
          <div class="font-semibold">
            {{ route.params.transaction_id ? 'Edit' : 'New' }} {{ workflow.name.toLowerCase() }}
          </div>
          <div class="text-xs font-normal">
            {{ workflow.description }}
          </div>
        </div>
      </template>
    </HawkPageHeader>
    <div class="mx-4">
      <HawkLoader v-if="state.initial_loading" />
      <Vueform
        v-else
        ref="form$"
        v-model="form"
        :display-errors="false"
        :attachment_config="{ meta: { service: 'inventory', id: 'upload' } }"
        size="sm"
        :endpoint="save"
        :loading="state.loading"
        :columns="{
          default: { container: 12, label: 12, wrapper: 12 },
          sm: { container: 12, label: 12, wrapper: 12 },
        }"
        @updated="onFormUpdated"
      >
        <div class="col-span-12 flex flex-col gap-5">
          <div class="grid grid-cols-2 gap-x-[120px] gap-y-5 max-w-[800px]">
            <div>
              <TextElement
                name="number"
                :label="`${workflow.name} ${$t('Number')}`"
                :placeholder="`Enter ${workflow.name} number`"
                :description="$t('Configure automatic number generation')"
                :rules="['required']"
              />
            </div>
            <div>
              <DateTimeElement
                name="date"
                :label="$t('Date')"
                :default="state.current_date"
                :description="`Select date when ${workflow.name.toLowerCase()} is created`"
                :rules="['required']"
                :options="{
                  'min-date': min_date,
                  'max-date': state.current_date,
                  'auto-apply': false,
                  'action-row': { showPreview: false },
                  'auto-position': false,
                }"
              />
            </div>
            <div>
              <SelectElement
                ref="from_stock"
                name="from_stock"
                :items="source.items"
                :search="true"
                track-by="name"
                :native="false"
                :label="source.label"
                input-type="search"
                autocomplete="off"
                label-prop="name"
                value-prop="uid"
                :disabled="!!source.default || (!!route?.params?.transaction_id && adjustment_details?.shipment_allocation)"
                :default="source.default"
                :rules="['required']"
                :placeholder="`${$t('Select')} ${source.name}`"
                :description="`Choose ${
                  source.name
                } to ${workflow.slug.toLowerCase()} items from`"
              >
                <template v-if="source.name !== 'asset'" #before-list>
                  <p
                    v-if="auth_store.check_permission(`create_${source.name}s`, route.params.asset_id)"
                    class="px-3 pt-1.5 pb-1 text-primary hover:bg-gray-100 font-medium"
                    @click="create_location(from_stock, source)"
                  >
                    + {{ $t('Add') }} {{ source.name }}
                  </p>
                </template>
              </SelectElement>
            </div>
            <div>
              <SelectElement
                v-if="workflow.stock_operation !== 'no_op'"
                ref="to_stock"
                name="to_stock"
                :items="destination.items"
                :search="true"
                track-by="name"
                :native="false"
                :label="destination.label"
                input-type="search"
                autocomplete="off"
                label-prop="name"
                value-prop="uid"
                :disabled="!!destination.default || (!!route?.params?.transaction_id && adjustment_details?.shipment_allocation)"
                :default="destination.default"
                :rules="['required']"
                :placeholder="`${$t('Select')} ${destination.name}`"
                :description="`Choose ${
                  destination.name
                } to ${workflow.slug.toLowerCase()} items`"
              >
                <template v-if="destination.name !== 'asset'" #before-list>
                  <p
                    v-if="auth_store.check_permission(`create_${destination.name}s`, route.params.asset_id)"
                    class="px-3 pt-1.5 pb-1 text-primary hover:bg-gray-100 font-medium"
                    @click="create_location(to_stock, destination)"
                  >
                    + {{ $t('Add') }} {{ destination.name }}
                  </p>
                </template>
              </SelectElement>
            </div>
          </div>

          <hr>

          <InventoryTransactionFormItems

            :value="state.adjustment_lines"
            :workflow="workflow"
            :from_stock="form.from_stock"
            :to_stock="form.to_stock"
            :form_workflow_config="{
              transaction_details: form,
              form_submissions: adjustment_form_submissions.form_submissions,
              workflow,
              handle_draft: save,
            }"
            @update="state.adjustment_lines = $event, state.disable_cta = false"
            @disable-cta="state.disable_cta = $event"
            @update-form-submissions="state.form_submissions = $event"
            @disable-save="state.disabled_save = $event"
          />

          <hr>

          <div class="max-w-[800px] grid gap-4">
            <div class="font-semibold">
              {{ $t('Other details') }}
            </div>
            <TextareaElement
              name="remarks"
              :label="$t('Remarks')"
              :placeholder="$t('Add comments here')"
              :columns="{
                default: { container: 12, label: 6, wrapper: 12 },
                sm: { container: 12, label: 6, wrapper: 12 },
              }"
            />
            <MultifileElement
              name="attachments"
              :label="$t('Attachments')"
              class="-mt-2"
              :presets="['hawk_file_element']"
              :options="{
                clickable_text: $t('Click to upload'),
                text: $t('or drag and drop'),
                description: $t('PNG, JPG, PDF'),
              }"
              accept="*"
              :auto="false"
              :drop="true"
              :format-data="format_attachment_data"
              :format-load="load_attachment_data"
              :columns="{
                default: { container: 12, label: 6, wrapper: 12 },
                sm: { container: 12, label: 6, wrapper: 12 },
              }"
            />
            <InventoryCustomFieldInput
              :key="`WF-InventoryCustomFieldInput${custom_fields_config?.length}`"
              :options="{
                name: 'custom_fields',
              }"
              :data="custom_fields_config"
              :input_size="6"
              :label_size="6"
              :attached_to_uid="workflow?.uid"
              attached_to="item_workflow"
            />
          </div>
          <InventoryFormWorkflows
            v-if="!adjustment_form_submissions.has_items"
            :transaction_details="form"
            :form_submissions="adjustment_form_submissions.form_submissions"
            :workflow="workflow"
            :handle_draft="save"
            @update="state.form_submissions = $event"
            @disable-save="state.disabled_save = $event"
          />
          <div class="sticky bottom-0 border-t border-t-gray-200 mt-6 bg-white z-1 col-span-12">
            <div class="flex justify-end w-full py-5 gap-3">
              <HawkButton :disabled="state.is_draft_loading" :type="has_only_draft ? 'outlined' : 'text'" @click="router.back()">
                {{ $t("Cancel") }}
              </HawkButton>
              <HawkButton
                v-if="route.params.transaction_id ? adjustment_details?.permissions?.update : workflow?.permissions?.draft"
                :type="has_only_draft ? 'default' : 'outlined'"
                :loading="state.is_draft_loading || form$?.submitting || form$?.isLoading"
                :disabled="form$?.invalid || form$?.hasErrors || form$?.submitting || form$?.isLoading || state.disable_cta"
                @click="save(true)"
              >
                {{ $t('Save as draft') }}
              </HawkButton>
              <div v-tippy="{ content: state.disabled_save ? $t('Please submit the form to proceed') : '', placement: 'top' }">
                <HawkSubmitButton
                  v-if="route.params.transaction_id ? adjustment_details?.permissions?.publish : workflow?.permissions?.publish"
                  :disabled="state.is_draft_loading || state.disable_cta || state.disabled_save"
                  :form$="form$"
                >
                  {{ $t('Save & Publish') }}
                </HawkSubmitButton>
              </div>
            </div>
          </div>
        </div>
      </Vueform>
    </div>
  </div>
</template>
