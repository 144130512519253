<script setup>
import { useModal } from 'vue-final-modal';
import SaveTemplatePopup from '~/forms/components/form-builder/form-builder-save-template-popup.vue';
import FormBuilderTableSection from '~/forms/components/form-builder/form-builder-table-section.vue';
import FormBuilderChecklistFieldV2 from '~/forms/components/form-builder/form-builder-checklist-field-v2.vue';
import FormBuilderDuplicateSectionOrFieldPopup from '~/forms/components/form-builder/form-builder-duplicate-section-or-field-popup.vue';

import { getFieldComponentData, getFieldComponentType } from '~/forms/composables/form-field-schema.composable.js';
import { useFormBuilderAllFields } from '~/forms/composables/form-builder-all-fields.composable.js';
import { useFieldCondition } from '~/forms/composables/form-builder-field-schema.composable.js';

import TrashIcon from '~icons/hawk/trash-three';
import CopyIcon from '~icons/hawk/copy-three';

const props = defineProps(['section', 'selected_field']);

const emits = defineEmits(['select_field', 'duplicate_field']);

const draggable = defineAsyncComponent(() => import('vuedraggable'));

const section = props.section.element;
const table_fields = ref(0);
const $services = inject('$services');
const $t = inject('$t');
const counter = ref(0);
const $toast = inject('$toast');
const $track_event = inject('$track_event');
const form_template_detail_store = inject('form_template_detail_store');
const is_deleting = ref(null);
const is_duplicating = ref(null);
const { field_map } = useFormBuilderAllFields();
const { get_chained_fields, get_chain_condition_msg } = useFieldCondition();
const active_fields = computed(() => section.fields.filter(field => field.status === 'active' && field.uid));
const has_readonly_access = computed(() => form_template_detail_store.form_template_detail.status === 'published' || !form_template_detail_store?.form_template_detail?.can_modify_template);

function saveTemplate(section, field) {
  const { open: openSaveTemplatePopup, close: closeSaveTemplatePopup } = useModal({
    component: SaveTemplatePopup,
    attrs: {
      section,
      field: field.element,
      onClose() {
        closeSaveTemplatePopup();
      },
    },
  });
  return openSaveTemplatePopup();
}

async function deleteField(field) {
  const chained_fields = get_chained_fields([field?.element?.uid]);

  if (chained_fields.length) {
    const error_message = get_chain_condition_msg(form_template_detail_store.form_template_detail.workflow, chained_fields, 'field');
    $toast({ text: error_message, type: 'error', timeout: 5000 });
    return;
  }

  try {
    is_deleting.value = true;
    await form_template_detail_store.delete_field(field?.element?.uid, { section_index: props.section.index, field_index: field.index });
    emits('select_field', null);
    is_deleting.value = false;
  }
  catch (e) {
    logger.log(e);
    $toast({ text: e?.data?.message || 'Field deleting failed!', type: 'error' });
    is_deleting.value = false;
  }
}

async function loadFromTemplate(data, section, fields) {
  try {
    const response = await $services.forms.post({
      attribute: 'fields/template/load',
      body: {
        field: {
          section: section.uid,
          name: data.added.element.name,
          uid: data.added.element.uid,
          previous_field: data.added.newIndex > 0
            ? fields[data.added.newIndex - 1].uid
            : null,
        },
      },
    });
    if (response.data.field)
      section.fields.splice(data.added.newIndex, 1, response.data.field);
    $track_event('add_field', {
      type: field_map[response.data.field?.properties?.type].name,
      template_used: true,
    });
    return response;
  }
  catch (e) {
    section.fields.splice(data.added.newIndex, 1);
    $toast({ text: e?.data?.message || 'Field adding failed!', type: 'error' });
  }
}

async function fieldDropped(data, section) {
  try {
    const fields = section.fields;

    if (data.added.element.is_template)
      return await loadFromTemplate(data, section, fields);

    data.added.element.previous_field
      = data.added.newIndex > 0
        ? fields[data.added.newIndex - 1].uid
        : null;

    const new_field = data.added.element;
    const payload = {
      name: '',
      type: new_field.dataType,
      // eslint-disable-next-line no-prototype-builtins
      mandatory: new_field.hasOwnProperty('mandatory') ? new_field.mandatory : true,
      properties: {
        type: new_field.type,
        ...(new_field.properties ? new_field.properties : {}),
      },
      section: section.uid,
      previous_field: new_field.previous_field,
      config: new_field.config,
      status: 'active',
    };
    const added_index = data.added.newIndex > 0 ? data.added.newIndex : 0;
    emits('select_field', { element: payload, index: added_index });
    section.fields.splice(added_index, 0, payload);
  }
  catch (e) {
    logger.log(e);
    $toast({ text: e?.data?.message || 'Field adding failed!', type: 'error' });
    form_template_detail_store.reset_form_template(section.uid);
  }
}

async function duplicate_field(field, section) {
  const { open: openDuplicateFieldPopup, close: closeDuplicateFieldPopup } = useModal({
    component: FormBuilderDuplicateSectionOrFieldPopup,
    attrs: {
      header: $t('Copy field'),
      type: 'field',
      section,
      onClose() {
        closeDuplicateFieldPopup();
      },
      async onSave(data) {
        try {
          is_duplicating.value = true;
          const fields = section.fields;
          const payload = {
            ...field,
            name: data.name,
            section: section.uid,
            previous_field: field.uid,
            uid: undefined,
          };
          const response = await $services.forms.post({
            attribute: 'fields',
            body: {
              field: payload,
            },
          });
          fields.push(response.data.field);
          form_template_detail_store.reset_form_template(section.uid);
          $track_event('add_field', {
            type: field_map[response.data.field?.properties?.type].name,
            template_used: response.data.field?.is_template,
          });
          is_duplicating.value = false;
        }
        catch (e) {
          logger.error(e);
          $toast({ text: e?.data?.message || 'Field duplicating failed!', type: 'error' });
          is_duplicating.value = false;
        }
      },
    },
  });
  openDuplicateFieldPopup();
}

async function fieldMoved(data, fields) {
  try {
    const new_index = data?.moved?.newIndex;
    const moved_field = fields[
      new_index
    ];
    if (new_index - 1 >= 0)
      moved_field.previous_field = fields[new_index - 1].uid;
    else
      moved_field.previous_field = null;
    if (moved_field.uid) {
      const response = await $services.forms.patch({
        attribute: `fields/${moved_field.uid}`,
        body: { field: moved_field },
      });
      fields[data.moved.newIndex] = { ...fields[data.moved.newIndex], ...response.data.field };
    }
  }
  catch (e) {
    [fields[data.moved.newIndex], fields[data.moved.oldIndex]] = [fields[data.moved.oldIndex], fields[data.moved.newIndex]];
    form_template_detail_store.reset_form_template(section.uid);
    $toast({ text: e?.data?.message || 'Field Updating failed!', type: 'error' });
  }
}

function fieldUpdated(data, section) {
  if (data.removed)
    return;
  if (data.added)
    fieldDropped(data, section);
  else
    fieldMoved(data, section.fields);
}

function selectField(field, event) {
  emits('select_field', { ...field, focus_description: event.srcElement.classList.contains('field_description'), counter: counter.value++ });
}
</script>

<template>
  <hawk-loader v-if="section.type === 'loading'" />
  <draggable
    v-else-if="section.type === 'default'"
    :key="section.fields"
    :group="{
      name: `${section.uid}fields`,
      pull: [`${section.uid}fields`],
      put: [
        'fields',
        `${section.uid}fields`,
      ],
    }"
    class="fields-list"
    :list="section.fields" tag="div" handle=".field-handle" item-key="uid"
    @change="fieldUpdated($event, section)"
  >
    <template #item="field">
      <div v-if="field.element.status === 'active'">
        <div
          class="grid gap-4  hover:bg-gray-50 rounded-lg field border-transparent border"
          :class="{ 'bg-gray-50 !border-gray-200 selected': props.selected_field?.field === field.element }"
          style="grid-template-columns: 1rem 1fr 15rem;"
          @click.stop="$event => section.type === 'default' ? selectField(field, $event) : '' "
        >
          <hawk-button type="plain" class="options invisible field-handle cursor-grab mt-2">
            <span class="text-lg  text-gray-300 "><icon-hawk-drag-icon class="h-4 w-4 text-gray-300" /></span>
          </hawk-button>
          <div>
            <Vueform v-if="getFieldComponentType(field.element) === 'SliderElement'" :display-errors="false" :add-classes="{ ElementLabel: { wrapper: '!text-sm' } }">
              <SliderElement
                class="py-3 pointer-events-none"
                :model-value="0"
                :rules="[field.element?.mandatory ? 'required' : 'nullable']"
                :name="field.element?.uid || 'new_one'"
                :label="field.element?.name || ' '"
                :description="field.element?.description"
              />
            </Vueform>
            <component
              :is="getFieldComponentType(field.element)"
              v-else
              :class="getFieldComponentType(field.element) === 'SelectElement' ? 'py-4' : 'py-4 pointer-events-none'"
              v-bind="getFieldComponentData(field.element, { is_builder: true })"
              :name="field.element?.uid || 'new'"
              :label="field.element?.name || ' '"
              :description="field.element?.description"
            />
          </div>
          <div v-if="section.type === 'default' && field.element.uid" class="justify-end relative flex invisible options mt-2">
            <hawk-button icon type="plain" :loading="is_duplicating" class="hover:!bg-gray-200 mr-1" @click.stop="duplicate_field(field.element, section)">
              <CopyIcon class="text-lg" />
            </hawk-button>
            <div v-tippy="{ content: active_fields.length === 1 ? $t('Cannot delete the only field. Please add another field to continue.') : '' }">
              <hawk-button icon type="plain" :loading="is_deleting" :disabled="active_fields.length === 1" class="hover:!bg-gray-200 mr-1" @click.stop="deleteField(field)">
                <TrashIcon class="text-lg" />
              </hawk-button>
            </div>
            <div v-if="field?.element?.type !== 'auto_number'" @click.stop>
              <hawk-menu additional_trigger_classes="!ring-0" class="flex" :items="[{ label: $t('Save as template'), on_click: () => saveTemplate(section, field) }]">
                <template #trigger>
                  <hawk-button icon type="plain" class="hover:!bg-gray-200 mr-1">
                    <icon-hawk-dots-vertical class="text-lg" />
                  </hawk-button>
                </template>
              </hawk-menu>
            </div>
            <div class="absolute -top-8 bg-gray-50 rounded-lg p-2 text-blue-600 type">
              <div class="bg-blue-50 rounded-lg px-2 text-primary-700 text-xs ">
                {{ field_map[field.element.properties.type]?.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </draggable>
  <FormBuilderTableSection
    v-else-if="section.type === 'tablev2'" :key="`${table_fields}_${props.section.element?.fields?.length || 0}`" :section="props.section.element" class="px-8" :disabled="has_readonly_access" @update_field="table_fields++"
  />
  <div v-else>
    <div v-for="field in section.fields" :key="field.uid" class="px-4">
      <SignatureElement v-if="section.type === 'signature'" :name="field.uid" class="pointer-events-none" />
      <FormBuilderChecklistField v-else-if="section.type === 'checklist'" :field="field" />
    </div>
    <FormBuilderChecklistFieldV2 v-if="section.type === 'checklistv2'" :section="section" />
  </div>
</template>

<style lang="scss" scoped>
.field{
  &.selected{
    .type{
      display:none;
    }
  }
  &:hover{
  .options{
    visibility: visible;
  }
  }
}
</style>
