<script>
import { FlexRender } from '@tanstack/vue-table';
import dompurify from 'dompurify';
import VueformTableRowObject from './vueform-table-row-object.vue';

export default {
  components: {
    FlexRender,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    selected_rows: {
      type: Object,
      default: () => {},
    },
    columns: {
      type: Array,
    },
    table_col_order: {
      type: Array,
    },
    additional_row_classes: {
      type: String,
      required: false,
      default: '',
    },
    frozen_col_id: {
      type: String,
    },
    arrows: {
      type: String,
      required: false,
      default: 'left',
    },
    is_pagination_enabled: {
      type: Boolean,
      default: true,
    },
    show_column_borders: {
      type: Boolean,
      default: true,
    },
    is_vueform_element_enabled: {
      type: Boolean,
      default: false,
    },
    striped: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {},
      cellDataLength: 0,
      VueformTableRowObjectComponent: markRaw(VueformTableRowObject),
    };
  },

  methods: {
    getCellData(row) {
      const cellData = row.getVisibleCells().sort((a, b) => this.table_col_order.indexOf(a.column.id) - this.table_col_order.indexOf(b.column.id));
      this.cellDataLength = cellData.length;
      return cellData;
    },
    sanitizeHtml(html_string) {
      return dompurify.sanitize(html_string);
    },
    onRowClick(e, row, cell) {
      this.$parent.$emit('cellClick', cell);
      if (cell.column.id !== 'select') {
        this.$parent.$emit('rowClicked', row.original, e, row);
        if (this.$parent.get_visible_columns.includes('select') && this.$parent.select_item_on_row_click)
          this.$parent.updateSelectedRows(row);
      }
    },
  },
};
</script>

<template>
  <template v-for="(row, index) in data" :key="row.id">
    <component
      :is="is_vueform_element_enabled ? 'ObjectElement' : 'tr'"
      :templates="{ ObjectElement: VueformTableRowObjectComponent }"
      :name="row?.original?.uid"
      class="group cursor-pointer hover:bg-gray-50"
      :class="[additional_row_classes, striped ? 'even:bg-gray-50' : '']"
      @dblclick="$parent.$emit('rowDblClick', row.original)"
      @mouseenter="$parent.$emit('rowMouseOver', row.original)"
      @mouseleave="$parent.$emit('rowMouseLeave', row.original)"
    >
      <td
        v-for="(cell) in getCellData(row)"
        :key="cell.id"
        :style="{
          minWidth: `${cell.column.getSize()}px`,
          ...((cell.column.id === frozen_col_id) && { ...($parent.scroll_x >= 40 && { filter: 'drop-shadow(16px 0 16px rgb(0 0 0 / 0.05))' }) }),
          ...(cell.column.id === 'select' && { minWidth: '40px', maxWidth: '40px' }),
          ...($parent.cell_height && { height: $parent.cell_height }),
          ...($parent.formatTable && $parent.formatTable(cell)),
        }"
        :class="[
          cell?.column?.columnDef.custom_classes ? cell?.column?.columnDef.custom_classes : '',
          cell.column.id === 'select' ? 'px-0' : 'px-6',
          $parent.cell_height ? 'py-1' : 'py-3',
          show_column_borders || $parent.data_grid_lines?.vertical ? 'border-r' : '',
          striped && index % 2 !== 0 ? '!bg-inherit' : 'bg-white',
          (cell.column.id === frozen_col_id) ? 'sticky left-0 z-1 bg-white hover:bg-gray-50' : '',
          !$parent.data_grid_lines?.horizontal ? '!border-b-0' : 'border-b ',
          cell?.column?.columnDef?.font_medium ? 'font-medium' : 'font-normal',
        ]
        "
        class="first:border-l last:border-r text-left text-sm text-gray-800 relative group-hover:bg-gray-50 select-none"
        @click="onRowClick($event, row, cell)"
      >
        <div v-if="cell.column.id === 'select'" :class="[!($parent.freeze_table === row.id) && $parent.getColumnById[cell.column.id]?.show_on_hover && !Object.values(selected_rows).some(val => val) ? 'invisible group-hover:!visible' : 'visible']">
          <div class="flex h-5 items-center justify-center">
            <HawkCheckbox v-if="!row?.original?.disable_table_checkbox" :id="`table_checkbox_${row.id}`" class="absolute left-[35%] -my-2.5'" :model-value="Boolean(selected_rows[row.id])" @input="$parent.updateSelectedRows(row)" />
          </div>
        </div>
        <div class="flex items-center h-full" :class="{ 'hidden group-hover:visible': !($parent.freeze_table === row.id) && $parent.getColumnById[cell.column.id]?.show_on_hover }" :style="{ paddingLeft: cell.column.id === columns[columns[0]?.id === 'select' ? 1 : 0]?.id ? `${row.depth * 2}rem` : '' }">
          <span v-show="row.getCanExpand() && cell.column.id === columns[columns[0]?.id === 'select' ? 1 : 0]?.id" class="absolute flex" :style="{ [arrows]: `${row.depth * 2 + 0.3}rem` }">
            <slot name="expand-icon">
              <button
                :style="
                  { cursor: 'pointer', transform: row.getIsExpanded() ? 'rotate(90deg)' : 'rotate(0deg)' }
                "
                @click.stop="$parent.updateExpandedState(row, $event)"
              >
                <IconHawkChevronRight class="text-gray-800 mt-1 h-4 w-4" />
              </button>
            </slot>
          </span>
          <slot v-if="row.original" :name="cell.column.id" :data="cell">
            <HawkTableCellRenderer
              v-if="cell.column.columnDef.render_as"
              :cell="cell"
            />
            <div v-else-if="$parent.getColumnById[cell.column.id]?.renderHTML" v-html="sanitizeHtml(cell?.getValue() || '')" />
            <FlexRender
              v-else
              :render="cell.column.columnDef.cell"
              :props="cell.getContext()"
            />
          </slot>
          <div v-else-if="cell.column.id !== 'select'" class="w-full">
            <slot name="rowLoader">
              <HawkSkeleton
                custom_classes="w-4/5 py-2"
              />
            </slot>
          </div>
        </div>
      </td>
    </component>
    <tr v-if="row?.original?.uid && $slots[`row_info_${row.original.uid}`] && (!row.getCanExpand() || row.getIsExpanded())" class="border-0 bg-white">
      <td class="table-cell text-gray-900 border-x table-info-class" :colspan="$parent.get_visible_columns.length" :style="{ paddingLeft: `${row.depth * 2 + 4}rem` }">
        <slot :name="`row_info_${row.original.uid}`" :data="row" />
      </td>
    </tr>
  </template>
</template>
