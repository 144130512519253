<script setup>
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { RRule } from 'rrule';
import { useModal } from 'vue-final-modal';
import { groupBy, startCase } from 'lodash-es';
import FormSchedule from '~/forms/components/form-schedule.vue';
import TaskPriority from '~/tasks/components/atoms/task-priority.vue';
import AssociatedResourceDetails from '~/tasks/components/organisms/associated-resource-details.vue';

const props = defineProps({
  is_preview_mode: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['formData']);

dayjs.extend(isSameOrAfter);

const $date = inject('$date');
const form_detail_store = inject('form_detail_store');
const $toast = inject('$toast');

const form$ = ref(null);
const forms_detail = computed(() => {
  return form_detail_store?.form_detail;
});
const is_submitted = computed(() => form_detail_store?.form_detail?.status.name === 'Submitted');
const { members, owner, teams, created_at } = forms_detail.value;

const recurrence_text = computed(() => {
  if (forms_detail.value?.schedule?.rrule)
    // eslint-disable-next-line new-cap
    return new RRule.fromString(forms_detail.value.schedule.rrule).toText();
  return null;
});

const { open: openSchedulePopup, close: closeSchedulePopup, patchOptions: patchScheduleOptions } = useModal({
  component: FormSchedule,
});
function setSchedule() {
  patchScheduleOptions({
    attrs: {
      task_uid: forms_detail.value.uid,
      schedule: forms_detail.value.schedule ? { ...forms_detail.value.schedule, duration: forms_detail.value.duration } : undefined,
      start_date: forms_detail.value.start_date,
      onClose() {
        closeSchedulePopup();
      },
      async onUpdate(payload) {
        await form_detail_store.set_schedule(payload);
        closeSchedulePopup();
      },
    },
  });
  openSchedulePopup();
}

const one_step_edit_fields = ref({
  start_date: false,
  due_date: false,
  category: false,
});
const two_step_edit_fields = ref({
  tags: false,
  assignees: false,
  description: false,
});
const additional_resource_details = ref({});
function formatLoadData(data) {
  const fields = [
    'assignees',
    'start_date',
    'due_date',
    'category',
    'description',
    'tags',
    'priority',
  ];
  const load_data = {};
  fields.forEach((key) => {
    if (data?.[key])
      load_data[key] = data[key];

    // INFO: Handle 'not_set' with null map for BE for old forms
    if (key === 'priority' && data?.[key] === null)
      load_data[key] = 'not_set';
  });

  if (props.is_preview_mode)
    load_data.description = forms_detail.value?.template?.description;
  return load_data;
}

// location
async function updateLocation(payload) {
  try {
    const update_payload = payload;
    if (forms_detail.value?.properties)
      update_payload.properties = { ...forms_detail.value.properties, ...payload.properties };
    await form_detail_store.update_form_details({ body: update_payload });
    form_detail_store.forms_list_store.forms_track_events('Location changed', form_detail_store.form_detail.uid, {}, ['associated_with']);
  }
  catch (error) {
    $toast({
      title: 'Error',
      text: error?.data?.message,
      type: 'error',
      position: 'bottom-right',
    });
  }
}
// Reverts value to the actual value and disables click blocking
function revert(key) {
  two_step_edit_fields.value[key] = false;
  form$.value?.elements$[key]?.load(form_detail_store.form_detail[key], true);
}

const form_details = computed(() => form_detail_store?.form_detail);

onMounted(() => {
  form$.value.load(form_detail_store?.form_detail, true);
});

watch(form_details, (newVal) => {
  form$.value.load(newVal, true);
});

function update(key) {
  const payload = {};
  if (key === 'assignees') {
    payload.assignees = form$.value.data.assignees.map(assignee => assignee.uid);
  }
  else if (key === 'start_date' || key === 'due_date') {
    if (form$.value.data[key] !== null && form$.value.data[key] !== forms_detail.value[key]?.substring(0, 10))
      payload[key] = new Date(form$.value.data[key]).toISOString();
  }
  else { payload[key] = form$.value.data[key]; }
  if (Object.keys(payload).length)
    emit('formData', payload);
  // After update cleanup
  if (Object.keys(two_step_edit_fields.value).includes(key))
    two_step_edit_fields.value[key] = false;

  if (Object.keys(one_step_edit_fields.value).includes(key))
    one_step_edit_fields.value[key] = false;
  if (payload[key] !== undefined)
    form_detail_store.forms_list_store.forms_track_events(`${startCase(key)} updated`, form_detail_store.form_detail.uid, { mode: 'Single', count: 1 });
}

// Enables and disableds pointer events
function handleClickBlocking(class_name, block) {
  if (block) {
    document.getElementById('form-details').style.pointerEvents = 'none';
    document.getElementById('form-details').style.overflowY = 'disabled';
    document.getElementsByClassName(class_name)[0].style.pointerEvents = 'auto';
  }
  else {
    document.getElementById('form-details').style.pointerEvents = 'auto';
    document.getElementById('form-details').style.overflowY = 'scroll';
  }
}
function handleEdit(key) {
  two_step_edit_fields.value[key] = true;
}
// Adds outline classes and key class based on toggle status
const add_classes = computed(() => {
  const val = {};
  Object.keys(two_step_edit_fields.value).forEach((key) => {
    if (two_step_edit_fields.value[key])
      val[key] = {
        ElementLayout: {
          innerWrapper: 'outline outline-4 outline-primary-100 rounded-lg',
          container: key,
        },
        TagsElement: {
          select: {
            wrapper: 'outline-1 outline-offset-1 outline-primary-600 rounded-lg !outline',
          },
        },
      };
    else
      val[key] = {
        ElementLayout: {
          container: key,
        },
        TagsElement: {
          select: {
            wrapper: 'outline-zero',
            dropdownHidden: 'hidden',
          },
        },
      };
  });
  return val;
});
// Removes outline classes based on toggle status
const remove_classes = computed(() => {
  const val = {};
  Object.keys(two_step_edit_fields.value).forEach((key) => {
    if (!two_step_edit_fields.value[key])
      val[key] = {
        ElementLayout: {
          innerWrapper: 'outline outline-4 outline-primary-100 rounded-md',
        },
        TagsElement: {
          select: {
            wrapper: 'outline-1 outline-offset-1 outline-primary-500 rounded-md outline',
          },
        },
      };
    else
      val[key] = {
        TagsElement: {
          select: {
            container: 'focused:!border-primary-500',
            wrapper: 'outline-zero',
            dropdownHidden: 'hidden',
          },
        },
      };
  });
  return val;
});

const timeline_data = computed(() => {
  const approver_with_outcome_status = Array.isArray(forms_detail.value?.approvers)
    ? forms_detail.value?.approvers
      ?.filter(approver => !(approver.approver === false && approver.outcome === false))
      .map((approver) => {
        const approver_data = { ...approver };
        if (approver_data.outcome === null)
          approver_data.outcome_status = 'Pending';

        else if (approver_data.approver === false && approver_data.outcome === true)
          approver_data.outcome_status = 'Reviewed';

        else if (approver_data.outcome === true)
          approver_data.outcome_status = 'Approved';

        else if (approver_data.approver === true && approver_data.outcome === false)
          approver_data.outcome_status = 'Rejected';

        return approver_data;
      })
    : [];

  const grouped_data = groupBy(approver_with_outcome_status, 'outcome_status');
  const pending_uids = (grouped_data.Pending ?? []).map(approver => approver.uid);

  const transformed_data = approver_with_outcome_status.filter(approver => approver.outcome_status !== 'Pending');

  pending_uids.length && transformed_data.push({
    uid: pending_uids,
    outcome_status: 'Pending',
    timestamp: null,
    comment: null,
  });

  const sorted_approvals = transformed_data.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

  return sorted_approvals;
});

function setCreatedTag(e) {
  form$.value.elements$.tags.load([...form$.value.data.tags, e.uid]);
}

const is_disabled = computed(() => {
  if (props.is_preview_mode)
    return true;
  else if (forms_detail.value)
    return !forms_detail.value.can_modify || props.is_preview_mode || is_submitted.value || forms_detail.value.is_approval_pending;
  else return false;
});
</script>

<template>
  <Vueform
    v-if="forms_detail"
    ref="form$"
    size="sm"
    :presets="['static_display']"
    :class="{ 'pointer-events-none': is_disabled }"
    :format-load="formatLoadData"
    :override-classes="{
      ElementLayout: {
        innerContainer: 'flex items-center justify-between w-full',
        innerWrapper: 'w-full',
        outerWrapper: 'flex',
      },
      ElementLabel: {
        container: 'min-w-32',
      },
    }"
    :add-classes="{ ElementLabel: { container_horizontal_sm: 'text-type:form-pt-input-border-sm', container_horizontal_sm_SM: 'sm:text-type:form-pt-input-border-sm', container_horizontal_sm_MD: 'md:text-type:form-pt-input-border-sm' } }"
  >
    <hawk-assignee-input
      v-click-outside="() => revert('assignees')"
      :options="{
        name: 'assignees',
        label: $t('Assignees'),
        has_teams: true,
        canClear: false,
        placeholder: is_disabled ? '-' : $t('Select assignees'),
        size: 'sm',
        disabled: is_disabled,
      }"
      :format_load="true"
      :has_teams="true"
      :multi="true"
      :asset_id="forms_detail.target_element.asset"
      :tags_removable="two_step_edit_fields.assignees"
      :remove-classes="remove_classes.assignees"
      :add-classes="add_classes.assignees"
      :truncate_tag_length="20"
      @open="handleEdit('assignees')"
    >
      <template #after>
        <div v-if="two_step_edit_fields.assignees" class="flex items-center relative z-2">
          <icon-hawk-check class="text-blue-500 cursor-pointer mx-2 w-4 h-4" @click.stop="update('assignees')" />
          <icon-hawk-x-close class="text-gray-500 cursor-pointer w-4 h-4" @click.stop="revert('assignees')" />
        </div>
      </template>
    </hawk-assignee-input>
    <!-- Priority -->
    <SelectElement
      v-if="forms_detail.can_modify || forms_detail.priority !== 'not_set'"
      name="priority"
      :label="$t('Priority')"
      :placeholder="$t('Select Priority')"
      :items="form_detail_store.priority_values"
      value-prop="be_key"
      :can-clear="false"
      :can-deselect="false"
      :native="false"
      :disabled="is_disabled"
      @select="update('priority')"
    >
      <template #single-label="{ value }">
        <div class="flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3">
          <TaskPriority :priority="value.value" />
        </div>
      </template>
      <template #option="{ option }">
        <TaskPriority :priority="option.value" />
      </template>
    </SelectElement>
    <EmptyStaticElement
      v-else
      :label="$t('Priority')"
    />
    <!-- Start Date -->
    <DateTimeElement
      v-if="forms_detail.can_modify || forms_detail.start_date"
      :key="forms_detail.start_date"
      v-click-outside="() => one_step_edit_fields.start_date = false"
      name="start_date"
      class="date_time"
      :label="$t('Start date')"
      :options="{
        clearable: false,
        format: 'dd MMMM yyyy',
        disabled: is_disabled,
        startTime: { hours: 0, minutes: 0 },
        maxDate: forms_detail.due_date ? new Date(forms_detail.due_date).toJSON().slice(0, 10) : null,
        placeholder: is_disabled ? '-' : $t('Select start date'),
      }"
      :add-classes="{
        ElementLayout: {
          outerWrapper: '!flex !items-center',
          innerWrapper: 'hover:bg-gray-50 rounded-md',
        },
      }"
      @open="one_step_edit_fields.start_date = true"
      @update:model-value="update('start_date')"
    >
      <template #after>
        <div v-if="one_step_edit_fields.start_date && forms_detail.start_date" class="flex items-center mx-2">
          <IconHawkTrashThree
            class="cursor-pointer text-gray-600"
            @click.stop="() => { emit('formData', { start_date: null }); one_step_edit_fields.start_date = false }"
          />
        </div>
      </template>
    </DateTimeElement>
    <!-- Due Date -->
    <DateTimeElement
      v-if="forms_detail.can_modify || forms_detail.due_date"
      :key="forms_detail.start_date"
      v-click-outside="() => one_step_edit_fields.due_date = false"
      name="due_date"
      class="date_time"
      :class="{ overdue: dayjs().isSameOrAfter(dayjs(forms_detail.due_date)) }"
      :label="$t('Due date')"
      :options="{
        clearable: false,
        format: 'dd MMMM yyyy',
        disabled: is_disabled,
        placeholder: is_disabled ? '-' : $t('Select due date'),
        startTime: { hours: 23, minutes: 59 },
        minDate: forms_detail.start_date ? new Date(forms_detail.start_date) : null,
      }"
      :add-classes="{
        ElementLayout: {
          outerWrapper: '!flex !items-center',
          innerWrapper: 'hover:bg-gray-50 rounded-md',
        },
      }"
      @update:model-value="update('due_date')"
      @open="one_step_edit_fields.due_date = true"
    >
      <template #after>
        <div class="flex items-center">
          <IconHawkSchedule
            v-if="recurrence_text && !one_step_edit_fields.due_date"
            v-show="!two_step_edit_fields.assignees && !one_step_edit_fields.start_date"
            v-tippy="recurrence_text"
            class="cursor-pointer pointer-events-auto"
            @click.stop="is_disabled ? null : setSchedule()"
          />
          <div v-if="one_step_edit_fields.due_date && forms_detail.due_date" class="flex items-center mx-2">
            <IconHawkTrashThree
              class="cursor-pointer"
              @click="() => { emit('formData', { due_date: null }); one_step_edit_fields.due_date = false }"
            />
          </div>
        </div>
      </template>
    </DateTimeElement>
    <!-- Category -->
    <hawk-category-input
      v-click-outside="() => one_step_edit_fields.category = false"
      :options="{
        name: 'category',
        label: $t('Category'),
        placeholder: is_disabled ? '-' : $t('Select category'),
        multi: false,
        canClear: false,
        disabled: is_disabled,
      }"
      @select="update('category')"
      @deselect="update('category')"
      @open="one_step_edit_fields.category = true"
    >
      <template #after>
        <div v-if="one_step_edit_fields.category && forms_detail.category" class="flex items-center mx-2">
          <IconHawkTrashThree
            class="cursor-pointer"
            @click.stop="() => { emit('formData', { category: null }); one_step_edit_fields.category = false }"
          />
        </div>
      </template>
    </hawk-category-input>
    <!-- Tags -->
    <hawk-tags-input
      v-click-outside="() => revert('tags')"
      :options="{
        name: 'tags',
        label: $t('Tags'),
        placeholder: is_disabled ? '-' : $t('Select tags'),
        canClear: false,
        create: !is_disabled,
        disabled: is_disabled,
      }"
      :tags_removable="two_step_edit_fields.tags"
      :add-classes="add_classes.tags"
      :remove-classes="remove_classes.tags"
      :setter_function="setCreatedTag"
      @open="handleEdit('tags')"
    >
      <template #after>
        <div v-if="two_step_edit_fields.tags" class="flex items-center">
          <IconHawkCheck class="text-blue-500 cursor-pointer mx-3 w-4.5" @click.stop="update('tags')" />
          <IconHawkCross class="text-gray-500 cursor-pointer w-2.5" @click.stop="revert('tags')" />
        </div>
      </template>
    </hawk-tags-input>
    <!-- Location -->
    <element-hierarchy
      name="location"
      :label="$t('Location')"
      :target_element="forms_detail.target_element"
      :update_function="updateLocation"
      :disabled="is_disabled"
      @hierarchyData="$event => additional_resource_details = $event"
    />
    <!-- Description -->
    <EditorElement
      v-click-outside="() => revert('description')"
      name="description"
      :label="$t('Description')"
      :debounce="500"
      :placeholder="is_disabled ? '-' : $t('Add description')"
      :hide-tools="['bold', 'italic', 'strike', 'link', 'heading', 'quote', 'code', 'bullet-list', 'number-list', 'decrease-nesting', 'increase-nesting', 'attach', 'undo', 'redo']"
      :add-classes="add_classes.description"
      :remove-classes="remove_classes.description"
      :disabled="is_disabled"
      @click="forms_detail.can_modify && !props.is_preview_mode ? handleEdit('description') : null"
    >
      <template #after>
        <div v-if="two_step_edit_fields.description" class="flex items-center">
          <icon-hawk-check class="text-blue-500 cursor-pointer mx-2  w-4 h-4" @click.stop="update('description')" />
          <icon-hawk-x-close class="text-gray-500 cursor-pointer  w-4 h-4" @click.stop="revert('description')" />
        </div>
      </template>
    </EditorElement>
    <AssociatedResourceDetails
      class="mt-2 col-span-12 pointer-events-auto"
      :resource_details="additional_resource_details"
    />
    <div class="col-span-12 bg-gray-50 px-6 py-3 rounded-lg mt-4 truncate">
      <StaticElement
        class="h-[37px] flex items-center text-sm text-[#667085]"
        :label="`${$t('Created by')}:`" :columns="
          { container: 8, label: 6, wrapper: 12 }"
      >
        <HawkMembers
          v-if="owner"
          :members="owner"
          type="badge"
        />
      </StaticElement>

      <StaticElement
        class="h-[37px] flex items-center text-sm text-[#667085]"
        :label="`${$t('Created date')}:`" :columns="
          { container: 8, label: 6, wrapper: 12 }
        "
      >
        <span class="text-sm font-normal whitespace-nowrap">
          {{ created_at ? new Date(created_at).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "2-digit",
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
          }) : null }}
        </span>
      </StaticElement>
      <StaticElement
        class="h-[37px] flex items-center text-sm text-[#667085]"
        :label="`${$t('Template')}:`" :columns="
          { container: 8, label: 6, wrapper: 12 }"
      >
        <span class="text-sm font-normal whitespace-nowrap">
          {{ form_detail_store.form_template.name }}
        </span>
      </StaticElement>
    </div>
    <div v-if="timeline_data?.length" class="mt-4 col-span-12 pb-4">
      <hr>
      <div class="my-4 text-md font-semibold text-gray-900">
        {{ $t('Approvals') }}
      </div>
      <HawkTimeline
        timeline_class="bg-white"
        :details="timeline_data"
      >
        <template #icon="detail">
          <div v-if="['Approved', 'Reviewed'].includes(detail.data.outcome_status)">
            <IconHawkCheckCircle class="text-[#039855] h-[24px] w-[24px]" />
          </div>
          <div v-else-if="detail.data.outcome_status === 'Pending'">
            <div class="border-8 bg-gray-400 h-[24px] w-[24px] rounded-full" />
          </div>
          <div v-else-if="detail.data.outcome_status === 'Rejected'">
            <IconHawkXCircle class="text-[#F04438] h-[24px] w-[24px]" />
          </div>
        </template>
        <template #title="detail">
          <div class="font-semibold text-md flex mt-1">
            <div>
              {{ detail.data.outcome_status }}
            </div>
            <div class="flex items-center">
              <template v-if="Array.isArray(detail.data.uid)">
                <div class="flex flex-wrap">
                  <HawkMembers v-for="user_id in detail.data.uid" :key="user_id" class="mx-1 mb-1" :members="user_id" type="badge" />
                </div>
              </template>
              <template v-else>
                <HawkMembers class="mx-2" :members="detail.data.uid" type="badge" />
                <span v-if="detail.data.timestamp" class="text-gray-600 text-xs font-normal whitespace-nowrap ">{{ $date(detail.data.timestamp, "D MMMM, YYYY hh:mm a") }}</span>
              </template>
            </div>
          </div>
        </template>
        <template #description="detail">
          <span class="text-gray-600 text-xs">
            {{ detail.data.comment }}
          </span>
        </template>
      </HawkTimeline>
    </div>
  </Vueform>
</template>

<style lang="scss">
.static-display {
  border: 1px solid transparent !important;

  .caret {
    visibility: hidden;
  }
  &:hover {
    border: 1px solid transparent !important;
    background: #F9FAFB !important;
    .caret {
      visibility: visible;
    }
  }
}
.static-display-focused{
  border: 1px solid transparent !important;

&:focus-within {
   border: 1px solid #84CAFF;
   border-radius: 8px;
   .caret {
      visibility: visible;
    }
}
}

.no-padding {
  padding: 0 !important
}

.no-padding::placeholder {
  padding: 0 !important
}

.min-w-32 {
  min-width: 8rem;
}
</style>

<style lang="scss" scoped>
.date_time {
  :deep(.dp__input){
    border: 0 solid #3b82f6;
    background-color: transparent;
    padding-left: 12px;
  }
  :deep(.dp__input:focus){
    border: 1px solid;
  }

  :deep(.dp__input_icon) {
    display: none;
  }
}

.overdue {
  :deep(.dp__input){
    @apply text-error-700
  }
}
</style>
